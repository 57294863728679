import React, { Fragment } from "react"
import Header from "../components/Header"
import PageHeader from "../components/PageHeader"
import About from "../components/About/home-two"
import Services from "../components/Services"
import BrandLogo from "../components/BrandLogo"
import Funfact from "../components/Funfact"
import CallToAction from "../components/CallToAction"
import Footer from "../components/Footer"
import LoginRegister from "../components/LoginRegister"
import MobileMenu from "../components/MobileMenu"
import Features from "../components/Features"
import Testimonial from "../components/Testimonials/home-two"
import Team from "../components/Team/home-two"
import About2 from "../components/About/page"

import ServiceThumb from "../assets/img/about.jpg"

const PageAbout = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader bgImg={require("../assets/img/page-header.jpg")} title="ABOUT US" content="We Exist to be Carriers of His Love Revealers of HIS Compassion and Healers to the Nations." />
      <About2 title={"ABOUT US"} heading="More About <br/> Christ For The Nations" thumb={ServiceThumb} content="<b>We</b> are a registered faith based Christian Organization, Interdenominational Organization in Rwanda" />
      <Features classes={"sm-top"} />
      <About2
        title={""}
        heading="Our Statement of Faith"
        heading2="Objectives"
        thumb={ServiceThumb}
        content="<b>We</b> We believe in: <br> a) The Holy Bible which is the fully and uniquely inspired Word of God, the only infallible, sufficient and authoritative rule of faith and practice. 
  2 Timothy 3:15-17;<br> b) One God, eternally existent in three persons, Father, Son, and the Holy Spirit. Matthew 28:19; Ephesians 4:4-6. <br> c) The Deity of our Lord Jesus Christ, His Virgin birth, His vicarious and atoning death, through His shed blood, His bodily resurrection, His ascension, His mediatory intercession and His personal return in power and glory. Matthew 1:23; John 1:1-4 and 1:29; Acts 1:11 and 2:22-24; Romans 8:34; 1 Corinthians 15:3-4;2 Corinthians 5:21; Philippians 2:5-11; Hebrews 1:1-4 and 4:15. <br> d) The salvation of lost and sinful men through regeneration by the Holy Spirit. Salvation is by grace through faith. John 14:6; Acts 4:12;
   Romans 8:34; 1 Corinthians 15:3-4;<br> e) The indwelling of both the saved and the lost, they that are saved unto resurrection of life and those that are lost unto the resurrection of damnation. Corinthians 5:10-11; Ephesians 1:7 and 2:8-9; Titus 3:4-7. <br> The present ministry of the Holy Spirit by whose indwelling, the Christian is enabled to live a Godly life. 
John 3:5-8;Acts 1:8 and 4:31; Romans 8:9; 1 Corinthians 2:14; Galatians 5:16-18; Ephesians 6:12; Colossians 2:6-10.<br> g) The Spiritual Unity of all believers in our Lord Jesus Christ, who comprise the Church, the Body of Christ. He is the Only Saviour of the World. 
    1 Corinthians 12:12, 27; Ephesians 1:22-23.
<br>
h) We believe in the resurrection of both the saved and the lost; the saved unto the resurrection of eternal life and the lost unto the resurrection of damnation and eternal punishment. 1 Corinthians 15:51-57; Revelation 20:11-15.
<br>
I) We believe in  proclamation of the gospel that salvation is possible by grace through faith in Jesus Christ and through  discipleship grow into maturity in Christ is   a responsibility of all followers of Jesus Christ. Matthew 28:18-20; Acts 1:8; Romans 10:9-15; 1 Peter 3:15. J) In holy Christian living, and that we must have concern for the hurts and social needs of our fellow men and women ( Matthew 25:35-40.
"
        content2=" Reaching out to the lost with the Gospel of Jesus Christ as commanded by our Lord Jesus Christ in the Holy scriptures Mark 16:15 <br/>• Equipping Christian leaders, workers, believers through establishing schools of Ministry/training centers for the work of the ministry and their callings. <br/>• Organize and conduct national and international intercessory prayers for national leaders and nations. <br/>• Economic empowerment for women ,widows by establishing micro - income generating projects to support families. <br/>• Establish schools both pre primary ,primary and vocational schools to support disadvantaged children. <br/>• Advancing the Kingdom of God through Unity of purpose by networking with like-minded organizations, local and international, churches, individuals who share same or similar objectives.

"
      />
      <br></br>
      <br></br>
      <br></br>

      <Services />
      <br></br>
      <br></br>
      <br></br>
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  )
}

export default PageAbout
