import React from "react"
import Text from "../UI/Text"
import Widget from "../UI/Widget"
import List from "../UI/List"
import LI from "../UI/List/Item"
import { Link } from "react-router-dom"
import Logo from "../../assets/img/cfnmin/logo.png"

function Footer() {
  return (
    <footer className="footer-area sp-bottom">
      <div className="container">
        <div className="row mtn-40">
          <div className="col-lg-4 order-4 order-lg-0">
            <div className="widget-item">
              <div className="about-widget">
                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                  <img src={Logo} alt="Logo" />
                </Link>

                <Text>Christ for the Nations Ministries is a registered faith based Christian Organization, Interdenominational Organization in Rwanda.</Text>

                <Text classes="copyright-txt">&copy; {new Date().getFullYear()} Christ for the Nations. All Rights Reserved.</Text>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Information">
              <List classes="widget-list">
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/AboutUs"}`}>Our company</Link>
                </LI>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/contact"}`}>Contact us</Link>
                </LI>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/services"}`}>Our services</Link>
                </LI>
              </List>
            </Widget>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Social Links">
              <List classes="widget-list">
                <LI>
                  <a href="https://www.facebook.com/CNMiniatries/" target={"_blank"}>
                    Facebook
                  </a>
                </LI>
                <LI>
                  <a href="https://twitter.com/christ_nations" target={"_blank"}>
                    Twitter
                  </a>
                </LI>

                <LI>
                  <a href="https://www.instagram.com/__cfn_ministries____/ " target={"_blank"}>
                    Instagram
                  </a>
                </LI>
              </List>
            </Widget>
          </div>

          <div className="col-md-4 col-lg-3">
            <Widget title="Contact Us">
              <address>
                Promise House KG 11 Avenue P.O.Box 4507 <br />
                Cell Phone +250788422951 / 0788405019
              </address>
            </Widget>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
