import React, { Fragment } from "react"
import Header from "../components/Header"
import PageHeader from "../components/PageHeader"
import PageAbout from "../components/About/page"
import Services from "../components/Services"
import PricingTable from "../components/PricingTable"
import Testimonial from "../components/Testimonials/home-two"
import BrandLogo from "../components/BrandLogo"
import Funfact from "../components/Funfact"
import CallToAction from "../components/CallToAction"
import Footer from "../components/Footer"
import LoginRegister from "../components/LoginRegister"
import MobileMenu from "../components/MobileMenu"

import ServiceThumb from "../assets/img/about.jpg"

const PageService = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader bgImg={require("../assets/img/page-header.jpg")} title="OUR MINISTRIES" content="We Exist to be Carriers of His Love  Revealers of HIS Compassion and Healers to the Nations." />

      <Services classes="sm-top" />
      <br></br>
      <br></br>
      <br></br>
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  )
}

export default PageService
