import React, { Fragment } from "react"
import Header from "../components/Header"
import PageHeader from "../components/PageHeader"
import About from "../components/About/page"
import TeamMember from "../templates/Team"
import BrandLogo from "../components/BrandLogo"
import Funfact from "../components/Funfact"
import CallToAction from "../components/CallToAction"
import Footer from "../components/Footer"
import LoginRegister from "../components/LoginRegister"
import MobileMenu from "../components/MobileMenu"

import ServiceThumb from "../assets/img/about.jpg"

const PageTeam = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader bgImg={require("../assets/img/page-header.jpg")} title="KNOW ABOUT CFN Ministries THE ULTIMATE TEAM" content="We Exist to be Carriers of His Love Revealers of HIS Compassion and Healers to the Nations." />

      <TeamMember />

      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  )
}

export default PageTeam
